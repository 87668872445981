import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { ArrowRight, Checkmark } from 'react-bytesize-icons';
import Link from './link';

const styles = css`
  .image {
    @media (max-width: 60rem) {
      display: none !important;
    }
    @media (min-width: 60rem) {
      position: absolute !important;
      right: -2rem;
      bottom: 0;
      transform: perspective(1000px) rotateX(8deg) rotateY(-38deg) rotateZ(6deg);
      box-shadow:
        rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
      border-radius: 5px;
    }
  }

  .cta {
    color: #0a1530;
    max-width: 60rem;
    margin: 5rem 1rem 5rem;
    position: relative;
    text-align: center;

    &.dark {
      color: #fff;

      & .button:hover,
      & .button2:hover {
        color: #fff;
      }
    }

    @media (min-width: 60rem) {
      border-radius: 1rem;
      box-shadow:
        rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
      color: #fff;
      text-align: left;
      background: #4ca2cd linear-gradient(135deg, #4ca2cd, ease-in-out, #67b26f);
      padding: 3rem 33rem 3rem 3rem;

      & .button2 {
        color: #fff;
      }
    }

    @media (min-width: 60rem) {
      margin-left: auto;
      margin-right: auto;
    }
    & h3 {
      line-height: 1.2;
      font-size: clamp(20px, 20px + 0.8vw, 32px);
      font-weight: 900;
      margin-bottom: 0.5rem;
    }

    & p {
      margin: 0.5rem 0;
      font-size: 1.4rem;
    }

    & ul {
      list-style: none;
      margin: 0 0 0.5rem;

      & li {
        & svg {
          background: rgb(248, 248, 251);
          stroke: #67b26f;
          padding: 4px;
          border-radius: 100%;
          vertical-align: middle;
          margin-top: -3px;
        }
      }
    }

    & .button {
      pointer-events: all;
      margin: 0;
      font-size: calc(16px + 0.4vw);
      line-height: 1.25;
      padding: 0.33em 0;
      display: inline-block;
      text-decoration: none;
      line-height: 26px;
      transition: all 0.25s ease-in-out;
      border-radius: 0;
      font-weight: 700;
      box-shadow: initial;
      background: none;
      border: none;

      @media (min-width: 600px) {
        font-size: 1.4rem;
        &::after {
          display: none;
        }
      }

      &:hocus {
        color: #000;
        text-decoration: none;
      }

      & svg {
        margin-left: 10px;
      }

      @media (min-width: 60rem) {
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 3rem;
        box-shadow: none;
        border: none;
      }
    }

    & .button2 {
      pointer-events: all;
      margin: 0 0 0 2rem;
      font-size: calc(14px + 0.4vw);
      border-bottom: 1px solid #000;
      line-height: 1.25;
      padding: 0.33em 0;
      display: inline-block;
      text-decoration: none;
      line-height: 26px;
      transition: all 0.25s ease-in-out;
      border-radius: 0;
      box-shadow: initial;

      @media (min-width: 600px) {
        font-size: 1.1rem;
      }

      &::after {
        position: absolute;
        right: 0px;
        bottom: -1px;
        content: ' ';
        width: 100%;
        height: 1px;
        background: #fff;
        transition: 0.25s ease-out width;
      }

      &:hocus {
        color: #000;
        text-decoration: none;
        background: none;
        &::after {
          width: 0;
        }
      }

      @media (min-width: 60rem) {
        padding: 0.5rem 1rem;
        margin-left: 1rem;
        border-radius: 3rem;
        box-shadow: none;
        border: none;
        &::after {
          display: none;
        }
      }
    }
  }

  .ctaWrap {
    position: relative;
    padding: 0;
  }
`;

export default (props) => (
  <div className={styles.ctaWrap}>
    <div className={[styles.cta, props.darkbg ? styles.dark : ''].join(' ')}>
      <h3>{props.title || `Build your next project with Polypane`}</h3>
      {props.text ? (
        <p>{props.text}</p>
      ) : (
        <ul>
          <li>
            <Checkmark width={16} height={16} strokeWidth="8px" id="" /> Use all features on all plans
          </li>
          <li>
            <Checkmark width={16} height={16} strokeWidth="8px" id="" /> On Mac, Windows and Linux
          </li>
          <li>
            <Checkmark width={16} height={16} strokeWidth="8px" id="" /> 14-day free trial – no credit card needed
          </li>
        </ul>
      )}
      <Link className={styles.button} to="https://dashboard.polypane.app/register/">
        Try for free <ArrowRight width={16} height={16} strokeWidth="4px" id="" />
      </Link>

      <StaticImage src="../../assets/images/hero/app4.png" width={600} className={styles.image} alt="Polypane UI" />
    </div>
  </div>
);
